.contactBox {
	background-color: #121921;
	min-height: 240px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 8px 0;
	justify-content: space-evenly;
}

.contactTitle {
	line-height: 1.2;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	color: #f5f5f7;
	font-size: 2.6rem;
	font-weight: 300;
	padding-bottom: 20px;
	letter-spacing: -0.00833em;
}

.contact_info-wrapper {
	display: flex;
	flex-direction: column;
}

.contact-email,
.contact-number {
	color: #ffff;
	margin-bottom: 10px;
	cursor: pointer;
	text-decoration: none;
}

.fas.fa-phone-alt,
.far.fa-envelope {
	color: #0083ff;
	padding-right: 8px;
	font-size: 19px;
}

.contactButton:hover {
	background-color: #f5f6fa;
	color: #000;
}

.contact_social-icons {
	padding: 10px;
	display: flex;
	gap: 30px;
}

.contact_socialLinks {
	display: inline;
	color: #ffff;
	font-size: 22px;
	transition: all 0.3s ease;
}

.contact_socialLinks:hover {
	color: #0083ff;
}

/* MEDIA QUERIES */
@media (min-width: 340px) and (max-width: 768px) {
	.contactTitle {
		font-size: 2rem;
	}

	.contactBox {
		justify-content: space-evenly;
		min-height: 0;
		height: 200px;
	}

	.contactButton {
		padding: 10px 20px;
		font-size: 1rem;
	}
}
