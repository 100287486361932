.welcomeSection {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: calc(100vh - 80px);
	width: 100%;
}

.welcome__title {
	font-size: 3.75rem;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 300;
	opacity: 0.9;
	line-height: 1.2;
	letter-spacing: -0.00833em;
}

.down-button {
	position: absolute;
	top: 95%;
	left: 49%;
	background: none;
	border: none;
}

.social-icons {
	padding-top: 15px;
}

/* MEDIA QUERIES */
@media (min-width: 340px) and (max-width: 768px) {
	.welcomeSection {
		height: calc(667px - 60px);
	}

	.welcome__title {
		font-size: 2rem;
		line-height: 1.4;
		margin-bottom: 50px;
	}

	.social-icons {
		position: absolute;
		bottom: 60px;
	}
}
