.work {
	display: flex;
	flex-direction: column;
	height: 550px;
	justify-content: space-around;
	padding: 40px 0;
	margin-top: 50px;
}

.work_container {
	display: flex;
	justify-content: flex-end;
}

.work_heading {
	text-align: center;
	opacity: 0.9;
}

.projects {
	display: flex;
	align-items: center;
	width: 70%;
}

.project_list-container {
	display: flex;
	width: 30%;
	justify-content: flex-end;
	align-items: center;
}

.projects_ul {
	width: 60%;
	display: flex;
	flex-direction: column;
}

.projects-li {
	font-family: Arial, Helvetica, sans-serif;
	list-style: none;
	opacity: 0.5;
	padding: 30px 0 30px 15px;
	border-left: 1px solid lightgray;
	cursor: pointer;
	text-decoration: none;
}

.project--active {
	font-weight: 700;
	opacity: 1;
	border-left: 1px solid #000;
	font-family: Arial, Helvetica, sans-serif;
	list-style: none;
	padding: 30px 0 30px 15px;
	cursor: pointer;
	text-decoration: none;
}

.project-container {
	height: 350px;
	padding: 25px;
	display: flex;
	width: 100%;
	background-color: #0a3d62;
}

.project_details {
	display: flex;
	// height: 100%;
	width: 100%;
	justify-content: flex-end;
	color: #fff;
}

.project_title-name {
	font-size: 1.5rem;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1.334;
}

.project-description {
	font-size: 1rem;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0.00938em;
}

.project_text-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	max-height: 350px;
	overflow: auto;
	margin-left: 20px;
}

.pic {
	width: 73%;
}

/* MEDIA QUERIES */
@media (min-width: 340px) and (max-width: 768px) {
	.work_container {
		flex-direction: column;
	}

	.work {
		padding-bottom: 0;
		height: auto;
	}

	.project_list-container {
		width: 100%;
		min-height: 130px;
	}

	.projects_ul {
		flex-direction: row;
		padding: 10px;
		width: 100%;
	}

	.projects-li {
		text-align: center;
		font-size: 14px;
		padding: 10px 5px 10px 5px;
		border: none;
		border-bottom: 1px solid lightgray;
	}

	.project--active {
		font-weight: 700;
		font-size: 14px;
		border: none;
		border-top: 1px solid #000;
		border-bottom: 1px solid #000;
		padding: 10px 5px 10px 5px;
		text-align: center;
	}

	.projects {
		width: 100%;
	}

	.project-container {
		min-height: 540px;
		padding: 25px 10px;
		flex-direction: column;
	}

	.pic {
		width: 98%;
	}

	.project_title-name {
		font-size: 1.2rem;
	}

	.project_text-wrapper {
		margin-left: 0;
		padding: 8px;
	}

	.projectBtn {
		padding: 10px 20px;
		font-size: 1rem;
	}
}
