.calculatorContainer {
	padding: 20px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	background-color: #f5f7fa;
	margin: 50px auto 0px auto;
	overflow: hidden;
	max-width: 100vw;
}

.uiDesign {
	padding-top: 20px;
	padding-bottom: 10px;
	opacity: 0.9;
	font-size: 2.125rem;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1.235;
	letter-spacing: 0.00735em;
}

.top-container,
.bottom-container {
	height: 500px;
	width: 100%;
	display: flex;
}

.calculator_heading1-container,
.calculator_heading2-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 50%;
	flex-direction: column;
	text-align: justify;
}

.blue_calculator-container,
.dark_calculator-container {
	width: 50%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.blue_calculator,
.dark_calculator {
	height: 440px;
	width: 240px;
}

.calc_mode-heading {
	font-size: 3.75rem;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 300;
	line-height: 1.2;
	letter-spacing: -0.00833em;
}

.darkModeText {
	width: 340px;
	font-size: 1rem;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0.00938em;
}

.calc-description {
	font-size: 1rem;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0.00938em;
}

/* MEDIA QUERIES */
@media (min-width: 340px) and (max-width: 768px) {
	.calculatorContainer {
		margin: 0 auto;
		padding: 20px 10px;
	}

	.uiDesign {
		padding-bottom: 20px;
		font-size: 2rem;
	}

	.calc-description {
		text-align: center;
		padding-bottom: 15px;
	}

	.calc_mode-heading {
		font-size: 2.5rem;
	}

	.top-container,
	.bottom-container {
		height: auto;
		flex-direction: column;
		padding: 20px 0;
	}

	.calculator_heading1-container,
	.calculator_heading2-container {
		width: 100%;
		text-align: center;
	}

	.blue_calculator-container,
	.dark_calculator-container {
		width: 100%;
		height: auto;
		justify-content: center;
		align-items: center;
		padding-top: 10px;
	}

	.bottom-container {
		flex-direction: column-reverse;
	}

	.calc_mode-heading {
		text-align: center;
		padding-bottom: 10px;
		font-size: 1.6rem;
	}

	.darkModeText {
		width: 100%;
		text-align: center;
		padding: 15px;
	}
}
