.fas.fa-bars {
	display: none;
}

.header {
	height: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.logo {
	border: 1px solid gray;
	margin-left: 20px;
	padding: 5px 8px;
	cursor: default;
	font-weight: 200;
}

.bar {
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: #fff;
	position: fixed;
	height: 7%;
}

.fas.fa-bars {
	font-size: 1.5rem;
	color: #000;
	cursor: pointer;
}

.nav__list {
	margin-right: 25px;
}

.nav__item {
	display: inline-flex;
}

.nav__item a {
	text-decoration: none;
	color: #000;
	margin-right: 20px;
	cursor: pointer;
	opacity: 0.9;
}

@media (min-width: 340px) and (max-width: 667px) {
	.header {
		height: 60px;
	}

	.logo {
		margin-left: 20px;
		padding: 4px 6px;
		font-size: 14px;
	}

	.fas.fa-bars {
		display: block;
		margin-right: 20px;
	}

	.navigation {
		display: none;
	}
}
