.about-me {
	background-color: #0a3d62;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 40px 15px;
	// height: 390px;
}

.about-title {
	font-size: 2.125rem;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1.235;
	letter-spacing: 0.00735em;
}

.about-bio {
	margin-top: 20px;
	font-size: 1rem;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0.00938em;
}

.profile_pic-wrapper {
	background-image: url("../../img/profile_pic.jpg");
	height: 310px;
	width: 310px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	/* box-shadow: inset 5px 10px 18px #bdbdbd; */
	border-radius: 50%;
}

.text-wrapper {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 100%;
	color: #fff;
}

/* MEDIA QUERIES */
@media (min-width: 340px) and (max-width: 768px) {
	.about-me {
		flex-direction: column;
		padding: 40px 0;
		height: auto;
	}

	.about-title {
		font-size: 2rem;
	}

	.profile_pic-wrapper {
		height: 100px;
		width: 100px;
	}

	.text-wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		text-align: center;
	}

	.about-title {
		padding: 40px 0 20px 0;
	}

	.about-bio {
		text-align: justify;
		padding: 15px;
	}
}
