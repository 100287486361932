* {
	padding: 0;
	margin: 0;
	list-style: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html,
body {
	min-height: 100vh;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

img,
picture,
svg,
video {
	display: block;
	max-width: 100%;
}

input,
textarea,
button,
select {
	font: inherit;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
